// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-artists-tsx": () => import("./../../../src/pages/artists.tsx" /* webpackChunkName: "component---src-pages-artists-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-installations-tsx": () => import("./../../../src/pages/installations.tsx" /* webpackChunkName: "component---src-pages-installations-tsx" */),
  "component---src-pages-performances-tsx": () => import("./../../../src/pages/performances.tsx" /* webpackChunkName: "component---src-pages-performances-tsx" */),
  "component---src-pages-soundwaves-tsx": () => import("./../../../src/pages/soundwaves.tsx" /* webpackChunkName: "component---src-pages-soundwaves-tsx" */),
  "component---src-pages-special-projects-tsx": () => import("./../../../src/pages/special-projects.tsx" /* webpackChunkName: "component---src-pages-special-projects-tsx" */),
  "component---src-pages-sponsors-tsx": () => import("./../../../src/pages/sponsors.tsx" /* webpackChunkName: "component---src-pages-sponsors-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-workshops-tsx": () => import("./../../../src/pages/workshops.tsx" /* webpackChunkName: "component---src-pages-workshops-tsx" */),
  "component---src-templates-artist-template-tsx": () => import("./../../../src/templates/artistTemplate.tsx" /* webpackChunkName: "component---src-templates-artist-template-tsx" */),
  "component---src-templates-installation-template-tsx": () => import("./../../../src/templates/installationTemplate.tsx" /* webpackChunkName: "component---src-templates-installation-template-tsx" */),
  "component---src-templates-performance-template-tsx": () => import("./../../../src/templates/performanceTemplate.tsx" /* webpackChunkName: "component---src-templates-performance-template-tsx" */),
  "component---src-templates-workshop-template-tsx": () => import("./../../../src/templates/workshopTemplate.tsx" /* webpackChunkName: "component---src-templates-workshop-template-tsx" */)
}

